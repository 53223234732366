import React, {Fragment} from "react";
import {Button} from '@mui/material';

import './sustain.css';
import Meta from "../../components/Head/Meta";

export default function Sustain() {
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Soutenir Projets Opacity",
        "description": "Aidez-nous à développer des environnements de jeu novateurs. Vos dons soutiennent notre projet à but non lucratif pour une expérience de jeu enrichie.",
        "url": "https://www.projets-opacity.fr/association/soutenir",
        "mainEntity": {
            "@type": "HowTo",
            "name": "Comment Soutenir",
            "description": "Voici différentes façons de soutenir notre association.",
            "step": [
                {
                    "@type": "HowToStep",
                    "name": "1. Faire un don financier",
                    "url": "https://projets-opacity.assoconnect.com/collect/description/435431-p-campagne-de-dons"
                },
                {
                    "@type": "HowToStep",
                    "name": "2. Devenir membre",
                    "url": "https://projets-opacity.assoconnect.com/collect/description/435424-o-adhesion-membre-sympathisant"
                },
                {
                    "@type": "HowToStep",
                    "name": "3. S'intéresser et suivre notre association",
                    "url": "https://www.projets-opacity.fr/"
                }
            ]
        }
    };

    return (
        <>
            <Meta title="Soutenir - Projets Opacity" jsonLd={jsonLd}
                  description="Aidez-nous à développer des environnements de jeu novateurs. Vos dons soutiennent notre projet à but non lucratif pour une expérience de jeu enrichie."/>
            <section className="sustain flex-column flex-justify-start flex-align-start">
                <div className="title-sustain bg-content-primary flex-column flex-justify-start">
                    <h1>Soutenir l'association</h1>
                    <p className="subtitle">
                        Soutenir l'association Projets Opacity, c'est contribuer au développement d'environnements
                        multijoueurs vidéo-ludiques innovants et à la création de modifications de jeu captivantes. Vos
                        dons permettront de financer l'infrastructure, d'acquérir des équipements essentiels et de
                        couvrir les licences nécessaires. En aidant Projets Opacity, vous encouragez la formation de
                        communautés dynamiques et favorisez un projet à but non lucratif, destiné à enrichir
                        l'expérience des joueurs.
                    </p>
                    <div className="button-sustain flex-column flex-align-center flex-justify-center">
                        <Button variant="contained" color="secondary" href={'/association/a-propos'}>En savoir plus
                            sur le financement</Button>
                    </div>
                </div>
                <div className="widget-sustain bg-content-primary flex-column flex-justify-start">
                    <iframe title="Formulaire de donnation à l'association"
                            src="https://projets-opacity.assoconnect.com/collect/description/435431-p-campagne-de-dons?iframe=1"
                            height="100%" width="100%"></iframe>
                </div>
            </section>
        </>
    );
}